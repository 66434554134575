/* General styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-image: url('https://example.com/christmas-tree-background.jpg');
  /* Replace with your Christmas tree image URL */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.App {
  text-align: center;
  padding: 20px;
  color: #fff;
}

/* Title Styling */
h1 {
  font-size: 3.5rem;
  color: #c0392b;
  /* Festive bold red color */
  margin-bottom: 2rem;
  font-family: 'Georgia', serif;
  font-weight: bold;
  letter-spacing: 3px;
  /* text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5); */
  /* Subtle shadow for depth */
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #ffffff;
  /* Plain white background */
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); */
  /* Shadow for a floating effect */
  display: inline-block;
}

/* Subtext styling */
h1 .subtext {
  display: block;
  font-size: 1.2rem;
  font-weight: normal;
  /* margin-top: 10px;
  color: #555; */
  /* Muted grey color for subtext */
  letter-spacing: 1px;
}

/* Advent Calendar Grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  max-width: 900px;
  margin: 20px auto;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  border: 4px solid rgba(255, 255, 255, 0.3);
}

/* Styling for Doors */
.door {
  background-color: #b71c1c;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  border: 3px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  position: relative;
}

.door:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.door.open {
  background-color: #0a7f42;
  /* Festive green when open */
  color: white;
}

.door-locked {
  background-color: #8b0000;
}

.door-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lock-icon {
  font-size: 1.8rem;
  color: white;
}

.door p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Add snowflake animation to doors */
.door:hover::before {
  content: '❄️';
  font-size: 2rem;
  color: white;
  position: absolute;
  top: -10px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-content iframe {
  width: 100%;
  /* Take full width of the modal */
  max-width: 100%;
  /* Prevent overflow */
  height: 100vh;
  /* Maintain aspect ratio */
  aspect-ratio: 16 / 9;
  /* Preserve video aspect ratio */
  max-height: 85vh;
  /* Allow up to 85% of the viewport height */
  border: none;
  /* Remove iframe border */
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  max-width: 90%;
  /* Adjust modal width */
  max-height: 90%;
  /* Ensure modal fits within the viewport */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments for mobile */

/* Responsive adjustments for tablets */
@media (max-width: 768px) {
  .calendar-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 20px;
  }

  .door {
    aspect-ratio: 1 / 1;
    height: auto;
  }

  h1 {
    font-size: 2.8rem;
  }
}

/* Responsive adjustments for mobile */
@media (max-width: 600px) {
  .calendar-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 20px;
  }

  .door {
    aspect-ratio: 1 / 1;
    height: auto;
  }

/* Title styling */
h1 {
  font-size: 3.5rem;
  /* Slightly larger font size */
  background: linear-gradient(45deg, #ff0000, #ffd700);
  /* Sharper red to gold gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  font-family: 'Georgia', serif;
  font-weight: bold;
  /* Increase font weight */
  letter-spacing: 3px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  /* Reduce blur in the shadow */
  padding: 15px 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  /* Subtle outer shadow for more depth */
}

  .modal-content {
    padding: 20px;
  }

  .modal-content iframe {
    max-height: 100vh;
  }
}